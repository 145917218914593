import { CpxStart } from '../../../../../../core/components/start.component';
import { CpxEnd } from '../../../../../../core/components/end.component';
import { NfoStatus } from '../../../../../../core/components/status.component';
import '../../../../../../core/components/paginatedTable.scss';
import { ID_CONTRACT_CHANNEL_OPTION, ID_CONTRACT_CLIP_OPTION, ID_CONTRACT_MS_TEAMS, ID_DATA_ENTITY, } from '../../../../../constants/configs/config.constants';
import { useTranslations } from '../../../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../../../constants/transitions/uiTranslations';
import { IPartnerCustomerContracts } from "../../../../../constants/types/types.constants";
import cs from "classnames";
import { useTheme } from "../../../../../../core/utility/themeContext";
import './partnerSipTrunk.scss'
import { PortalContractData } from "compax-api";
import { CpxPaginatedTable } from "../../../../../../core/components/paginatedTable.component";
import { NfoSwitchAllProducts } from "../../../../switchActiveProducts/switchActiveProducts.component";
import React, { useState } from "react";
import { useSelectLang } from "../../../../../redux/store";
import { formatDate } from "../../../../../../core/utils";

type Props = {
  sipTrunkContract: PortalContractData;
  contracts: IPartnerCustomerContracts;
  countSip: number;
};

export const NfoPartnerSipTrunkBox = ({ sipTrunkContract, countSip, contracts }: Props) => {
  const translations = useTranslations(TRANSLATIONS.contractDetails);
  const theme = useTheme();
  const language = useSelectLang().selected;


  const channelOptions = contracts !== undefined &&
    contracts.filter(
      entry =>
        entry.componentOf === sipTrunkContract.id &&
        entry?.serviceType?.id + '' === ID_CONTRACT_CHANNEL_OPTION
    );


  const subOptions = contracts !== undefined &&
    contracts.filter(
      entry =>
        entry.componentOf === sipTrunkContract.id &&
        (entry?.serviceType?.id + '' === ID_CONTRACT_CLIP_OPTION || entry?.serviceType?.id + '' === ID_CONTRACT_MS_TEAMS)
    );

  const allOptions = []
  if (!!channelOptions && channelOptions.length > 0) {
    allOptions.push(...channelOptions)
  }
  if (!!subOptions && subOptions.length > 0) {
    allOptions.push(...subOptions)
  }
  const [filteredItems, setFilteredItems] = useState(allOptions)
  const [sortedItems, setSortedItems] = useState(filteredItems);


  const partnerSipTrunkRowData = (channelOption: any) => {
    return {
      id: channelOption.id + "",
      cellData: [
        <div className={cs(`line-le--${theme}`)}>
          <p className={cs("component-title", `component-title-le--${theme}`)}>{channelOption.contractName}</p>
        </div>,
        <div className={cs("startdate", `startdate-le--${theme}`, `line-le--${theme}`)}>
          {channelOption.startDateBilling && (
            <>
              <CpxStart/>
              {formatDate(new Date(channelOption.startDateBilling), language)}{' '}
            </>
          )}
        </div>,
        <div className={cs('enddate', `enddate-le--${theme}`, `line-le--${theme}`)}>
          {channelOption.endDateBilling && (
            <>
              <CpxEnd/>
              {formatDate(new Date(channelOption.endDateBilling), language)}
            </>
          )}
        </div>,
        <div className={cs(`line-le--${theme}`)}>
          <NfoStatus
            entity={{ id: ID_DATA_ENTITY.SERVICE }}
            status={channelOption.status}
            statusClassName={cs('status', `status-le--${theme}`)}
          />
        </div>
      ]
    }
  }
  return (
    <article className={cs('partnerSipTrunkTable', `partnerSipTrunkTable-le--${theme}`)}>
      <div className={cs('partnerSipTrunk', `partnerSipTrunk-le--${theme}`)}>
        <h4 className={cs("title", `title-le--${theme}`)}>
          {sipTrunkContract.contractName} - {countSip + 1}
        </h4>
      </div>
      <NfoSwitchAllProducts data={filteredItems} getFilteredDataBack={(data: any) => setSortedItems(data)} />
      <CpxPaginatedTable
        id="partnerSipTrunk"
        tableHeader={[
          { label: translations.languagePackOption(), sort: true, sortBy: 'contractName', sortMethod: 'string' },
          { label: translations.contractStart(), sort: true, sortBy: 'startDateBilling', sortMethod: 'date' },
          { label: translations.contractEnd(), sort: true, sortBy: 'endDateBilling', sortMethod: 'date' },
          { label: translations.status(), sort: true, sortBy: 'status.id', sortMethod: 'number' },
        ]}
        tableData={sortedItems.map(partnerSipTrunkRowData)}
        rowData={sortedItems}
        getSortedRowsBack={(sortedRows: any) => setFilteredItems(sortedRows?.slice())}
      />
    </article>
  );
};

import cs from "classnames";
import React, { PropsWithoutRef } from "react";
import { NavLink } from "react-router-dom";
import { NfoLogout } from "../../../auth/logout/logout.component";
import { routes } from "../../../../constants/routes/routes";
import { useSelectCustomer, useSelectUser } from "../../../../redux/store";
import { TRANSLATIONS } from "../../../../constants/transitions/uiTranslations";
import { useTranslations } from "../../../../utils/helper/utils";
import { usePopupMenu } from "../utils";
import "./userNavigation.scss";
import { ReactComponent as User } from "../../../../assets/icons/user.svg";
import { useTheme } from "../../../../../core/utility/themeContext";
import { PortalCustomerData } from "compax-api";

type Props = PropsWithoutRef<JSX.IntrinsicElements["div"]>;

export const NfoUserNavigation = (props: Props) => {
  const { expanded, setExpanded, ref } = usePopupMenu();
  const user = useSelectUser();
  const customer = useSelectCustomer() as PortalCustomerData;
  const translations = useTranslations(TRANSLATIONS.header);
  const theme = useTheme();
  const internalClassName = "userNavigation";


  return (
    <div
      {...props}
      className={cs(
        internalClassName,
        props.className,
        `${internalClassName}-le--${theme}`
      )}
      ref={ref}
    >
      <button
        id={"avatar"}
        className={cs(`${internalClassName}-avatar-le--${theme}`)}
        onClick={() => setExpanded(cur => !cur)}
      >
        <User/>
      </button>
        <div className={cs(`${internalClassName}-popup`, `${internalClassName}-popup-le--${theme}`, expanded && 'active')}>
          <div className={cs(`${internalClassName}-header`, `${internalClassName}-header-le--${theme}`)}>
            <div
              className={cs(
                `${internalClassName}-avatar`,
                `${internalClassName}-avatar-le--${theme}`
              )}
            >
              <User/>
            </div>
            <div className={cs(`${internalClassName}-userInfo`)}>
              <h3
                className={cs(
                  `${internalClassName}-userInfo`,
                  `${internalClassName}-userInfo-le--${theme}`
                )}
              >
                {customer?.customerName}
              </h3>
              <p className={cs(`${internalClassName}-preferredUsername`,
                  `${internalClassName}-preferredUsername-le--${theme}`)}>{user?.preferred_username}</p>
            </div>
          </div>
          <nav>
            <ul>
              <li className={cs(`${internalClassName}-listitem-le--${theme}`)}>
                <NavLink to={routes.customerEditProfile.path} id={"edit-profile"}>
                  {translations.editProfile()}
                </NavLink>
              </li>
              <li className={cs(`${internalClassName}-listitem-le--${theme}`)}>
                <NfoLogout id={"logout"}/>
              </li>
            </ul>
          </nav>
        </div>
    </div>
  );
};

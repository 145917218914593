import { PortalContractData } from "compax-api";
import { CpxStart } from "../../../../core/components/start.component";
import { CpxEnd } from "../../../../core/components/end.component";
import { NfoStatus } from "../../../../core/components/status.component";
import { useSelectContracts, useSelectLang } from "../../../redux/store";
import { NfoSubContracts } from "../subContracts/subContracts.component";
import "./telephoneConnection.scss";
import { useTheme } from "../../../../core/utility/themeContext";
import cs from "classnames";
import { ID_DATA_ENTITY } from "../../../constants/configs/config.constants";
import { IContracts } from "../../../constants/types/types.constants";
import { useTranslations } from '../../../utils/helper/utils';
import { TRANSLATIONS } from '../../../constants/transitions/uiTranslations';
import { CpxPaginatedTable } from "../../../../core/components/paginatedTable.component";
import { useState } from "react";
import { NfoSwitchAllProducts } from "../../switchActiveProducts/switchActiveProducts.component";
import { formatDate } from "../../../../core/utils";

type Props = {
  contract: PortalContractData;
};

export const NfoTelephoneConnection = ({ contract }: Props) => {
  const theme = useTheme();
  const internalClassName = "telephoneConnection";
  const contracts = useSelectContracts() as IContracts;
  const translations = useTranslations(TRANSLATIONS.contractDetails);

  let childContracts = contracts.filter(entry => entry.componentOf === contract.id);
  const [filteredItems, setFilteredItems] = useState(childContracts);
  const [sortedItems, setSortedItems] = useState(filteredItems);
  const language = useSelectLang().selected;



  const telephoneConnectionRowData = (childContract: any) => {
    return {
      id: childContract.id + "",
      cellData: [
        <div className={cs(`line-le--${theme}`)}>
          <p className={`telcon-row-title-le--${theme}`}>
            {childContract.contractName}
          </p>
          {childContract.contractDescription && (
            <span className={cs('desc', `desc-le--${theme}`)}>
              {childContract.contractDescription}
            </span>
          )}
          <NfoSubContracts parentId={childContract.id + ""} className={cs('desc', `desc-le--${theme}`)}/>
        </div>,
        <div className={cs('startdate', `startdate-le--${theme}`, `line-le--${theme}`)}>
          {childContract.startDateBilling && (
            <>
              <CpxStart className={"startdate"}/>
              {formatDate(new Date(childContract.startDateBilling as Date), language)}
            </>
          )}
        </div>,
        <div className={cs('startdate', `startdate-le--${theme}`, `line-le--${theme}`)}>
          {childContract.endDateBilling && (
            <>
              <CpxEnd className={"enddate"}/>
              {formatDate(new Date(childContract.endDateBilling  as Date), language)}
            </>
          )}
        </div>,
        <div className={cs(`line-le--${theme}`)}>
          <NfoStatus
            entity={{ id: ID_DATA_ENTITY.SERVICE }}
            status={childContract.status}
            statusClassName={cs('status', `status-le--${theme}`)}/>
        </div>
      ],
    };
  }
  return (
    <article className={cs(internalClassName, `${internalClassName}-le--${theme}`)}>
      <h4 className={`telcon-title telcon-title-le--${theme}`}>
        {contract.contractName}
      </h4>
      <NfoSwitchAllProducts data={filteredItems} getFilteredDataBack={(data: any) => setSortedItems(data)} />
      <CpxPaginatedTable
        id="telephoneConnection"
        tableHeader={[
          { label: translations.languagePackOption(), sort: true, sortBy: 'contractName', sortMethod: 'string' },
          { label: translations.contractStart(), sort: true, sortBy: 'startDateBilling', sortMethod: 'date' },
          { label: translations.contractEnd(), sort: true, sortBy: 'endDateBilling', sortMethod: 'date' },
          { label: translations.status(), sort: true, sortBy: 'status.id', sortMethod: 'number' },
        ]}
        tableData={sortedItems.map(telephoneConnectionRowData)}
        rowData={sortedItems}
        getSortedRowsBack={(sortedRows: any) => setFilteredItems(sortedRows?.slice())}
      />
    </article>
  );
};
